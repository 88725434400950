body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f0f4f8;
  margin: 0;
  padding: 0;
}

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  background-color: #2c3e50;
  color: white;
  padding: 1rem 0;
  text-align: center;
}

.app-header h1 {
  margin: 0;
  font-size: 2rem;
}

.main-content {
  flex-grow: 1;
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.quiz-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.quiz-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 150px;
  padding: 1rem;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.quiz-button:hover {
  background-color: #2980b9;
}

.icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.app-footer {
  background-color: #34495e;
  color: white;
  text-align: center;
  padding: 1rem 0;
  margin-top: auto;
}

/* Existing styles for other components... */

.quiz-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.quit-button {
  background-color: #e74c3c;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.quit-button:hover {
  background-color: #c0392b;
}

.question-container {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.question-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  width: 100%;
}

.option-button:hover {
  background-color: #f0f0f0;
}

.option-button.selected {
  background-color: #e1f5fe;
  border-color: #03a9f4;
}

.option-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.option-label {
  font-weight: bold;
  margin-right: 10px;
  min-width: 20px;
}

.option-text {
  flex-grow: 1;
}

.next-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.next-button:hover {
  background-color: #45a049;
}

/* Update existing styles */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.results-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.results-card {
  background-color: #f0f4f8;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
}

.score-circle {
  width: 150px;
  height: 150px;
  background-color: #3498db;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
  color: white;
}

.score-text {
  font-size: 3rem;
  font-weight: bold;
}

.total-text {
  font-size: 1.5rem;
}

.percentage {
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.performance-message {
  font-size: 1.2rem;
  color: #34495e;
}

.home-button {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #2980b9;
}

h2 {
  color: #333;
  margin: 0; /* Remove default margin */
}
